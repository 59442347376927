/*
===============================================================================
Common styles for form controls
===============================================================================
*/

.ui-form {
    margin: 10px 0;
}

.ui-form .header {
    margin: 20px 0 10px;
    text-align: center;
    text-transform: uppercase;
}

.ui-form ol {
    list-style: none;
}

.ui-form .w-buttons {
    float: none;
    margin: 20px 0;
    text-align: center;
}

.ui-form .w-buttons .k-button,
.form-actions .w-buttons .k-button {
    float: none;
    display: inline-block;
    user-select: none;
}

.label-nonfloating {
    display: block !important;
}

.image-preview {
    position: relative;
    margin-bottom: 15px;
    margin-right: 15px;
    float: left;
    padding-bottom: 20px;
}

.image-preview .image-preview-image {
    float: left;
}

.document-preview-filename {
    word-wrap: break-word;
    overflow: hidden;
}

.document-preview-delete-icon {
    cursor: pointer;
    position: relative;
    left: 5px;
    color: var(--text-primary);
}

.image-preview .document-preview-delete-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    width: 24px;
    height: 24px;
    font-size: var(--font-size-icon4);
    line-height: 1;
    filter: alpha(opacity=75);
    opacity: .75;
}
.image-preview .document-preview-delete-icon::before {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.image-preview span {
    position: absolute;
    left: 0;
    bottom: 0;
}

.horizontal-half {
    padding-right: 15px;
    border-right: 1px solid var(--grey-300);
    margin-bottom: 15px;
}

/* BP 5 */
@media only screen and (min-width: 1025px) {
    .ui-form {
        margin: 20px 0;
    }

    .ui-form .header {
        font-size: var(--font-size-h5);
        margin: 20px 0;
        padding-bottom: 15px;
        line-height: 1.5;
        text-align: center;
        text-transform: uppercase;
    }

    .ui-form ol {
        list-style: none;
    }

    .ui-form .form-actions {
        margin: 0;
        position: absolute;
        left: 398px;
        bottom: 0;
        min-width: 200px;
        text-align: right;
    }

    .ui-form .qr-code {
        display: inline-block;
        max-width: 100px;
        margin: 15px 20px 0 0;
    }

    .ui-form .qr-code p {
        margin: 0;
        text-align: center;
        font-weight: 600;
        margin-top: 10px;
    }
}
